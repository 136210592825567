import React, { useState, lazy, useEffect } from 'react'
import { Button, Table, Modal, Tooltip } from 'antd'
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import './student.scss'
import { useNavigate } from 'react-router-dom'

const StudentInfo = lazy(() => import('./StudentChildComponent/StudentInfo'))
const StudentDetails = lazy(() => import('./StudentChildComponent/StudentDetails'))
const Session = lazy(() => import('./StudentChildComponent/Session'))
const Event = lazy(() => import('./StudentChildComponent/Event'))
const Subjects = lazy(() => import('./StudentChildComponent/Subjects'))
const TargetCollege = lazy(() => import('./StudentChildComponent/TargetCollege'))

export default function Students() {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const headers = {}
  const [showDetails, setShowDetails] = useState(true)
  const [showCard, setShowCard] = useState(true)
  const [resultData, setData] = useState([])
  const [selectedStudentId, setSelectedStudentId] = useState(null)
  const [notesVisible, setNotesVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notesData, setNotesData] = useState([])
  const [cardNumber, setCardNumber] = useState(null)

  const navigate = useNavigate()

  const handleDocumentIconClick = (studentId) => {
    setSelectedStudentId(studentId)
    setShowDetails(false)
  }

  useEffect(() => {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    handleDataSource()
  }, [token])

  const handleDataSource = async () => {
    try {
      setLoading(true)
      const response = await fetch(
        `https://soft.recipe4foodies.com/api/CounselorStudents/GetStudentsByCounselor?counselorId=${userId}`,
        {
          headers: headers,
        },
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      setData(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleNotesClose = () => {
    setNotesVisible(false)
    setNotesData([])
  }

  const columns = [
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Parent ID',
      dataIndex: 'parentId',
      key: 'parentId',
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Tutor Group Sessions',
      dataIndex: 'tutorGroupSessions',
      key: 'tutorGroupSessions',
    },
    {
      title: 'Tutor Single Sessions',
      dataIndex: 'tutorSingleSessions',
      key: 'tutorSingleSessions',
    },
    {
      title: 'Counselor Session',
      dataIndex: 'counselorSessions',
      key: 'counselorSessions',
    },
    {
      title: 'Create Session',
      key: 'createSession',
      render: (record) => {
        const hasCounselorSessions = record.counselorSessions > 0

        return (
          <Tooltip title={!hasCounselorSessions ? 'No counselor sessions left' : ''}>
            <Button
              type="primary"
              style={{ display: 'block', backgroundColor: '#0d6efd' }}
              onClick={() => handleCreateSession(record.studentId)}
              disabled={!hasCounselorSessions} // Disable if no counselor sessions left
            >
              Create Session
            </Button>
          </Tooltip>
        )
      },
    },
    {
      title: 'Info',
      dataIndex: 'studentId',
      key: 'info',
      render: (studentId) => (
        <Button type="primary" onClick={() => handleDocumentIconClick(studentId)}>
          Info
        </Button>
      ),
    },
    {
      title: 'Notes',
      dataIndex: 'studentId',
      key: 'Notes',
      render: (studentId) => (
        <Button type="primary" onClick={() => handleNotesClick(studentId)}>
          Notes
        </Button>
      ),
    },
    {
      title: 'Courses',
      dataIndex: 'studentId',
      key: 'Courses',
      render: (studentId) => (
        <Button type="primary" onClick={() => handleCourseClick(studentId)}>
          Courses
        </Button>
      ),
    },
  ]

  const handleNotesClick = (studentId) => {
    navigate(`/studentNotes/${studentId}`)
  }

  const handleCourseClick = (studentId) => {
    navigate(`/studentCourses/${studentId}`)
  }

  const handleCreateSession = (studentId) => {
    navigate(`/counselorSession?studentId=${studentId}`)
  }

  const handleLinkClick = (linkId) => {
    setCardNumber(linkId)
    setShowDetails(false)
    setShowCard(false)
  }

  const handleBacktoStudents = () => {
    setCardNumber(null)
    setShowDetails(true)
  }
  const handleBack = () => {
    setShowCard(true)
    setCardNumber(null)
  }

  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            {showDetails ? (
              <div className="card">
                <div className="card-header" style={{ position: 'relative' }}>
                  <b>Students</b>
                </div>
                <div className="card-body">
                  <Table
                    className="table-responsive"
                    bordered
                    dataSource={resultData}
                    columns={columns}
                    loading={loading}
                    rowKey="studentId"
                  />
                </div>
              </div>
            ) : showCard ? (
              <div className="card">
                <div
                  className="card-header"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <b>Students Info Card</b>
                  <Button onClick={handleBacktoStudents}>Back to Students</Button>
                </div>
                <div className="card-body">
                  <div
                    style={{
                      marginBottom: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '30px', // Updated gap
                      flexWrap: 'wrap', // Ensure circles are responsive
                    }}
                  >
                    <div onClick={() => handleLinkClick(2)} className="circle-tab circle-tab-1">
                      <span title="Student Info">Info</span>
                    </div>
                    <div onClick={() => handleLinkClick(1)} className="circle-tab circle-tab-2">
                      <span title="Student Detail">Details</span>
                    </div>
                    <div onClick={() => handleLinkClick(3)} className="circle-tab circle-tab-3">
                      <span title="Remaining Sessions">Sessions</span>
                    </div>
                    <div onClick={() => handleLinkClick(4)} className="circle-tab circle-tab-4">
                      <span title="Student Event">Event</span>
                    </div>
                    <div onClick={() => handleLinkClick(5)} className="circle-tab circle-tab-5">
                      <span title="Subjects Taken">Subjects</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {cardNumber === 1 && (
                  <StudentInfo studentId={selectedStudentId} handleBack={handleBack} />
                )}
                {cardNumber === 2 && (
                  <StudentDetails studentId={selectedStudentId} handleBack={handleBack} />
                )}
                {cardNumber === 3 && (
                  <Session studentId={selectedStudentId} handleBack={handleBack} />
                )}
                {cardNumber === 4 && (
                  <Event studentId={selectedStudentId} handleBack={handleBack} />
                )}
                {cardNumber === 5 && (
                  <Subjects studentId={selectedStudentId} handleBack={handleBack} />
                )}
                {cardNumber === 6 && <TargetCollege />}
              </div>
            )}
          </div>
        </div>
      </DashboardLayout>
      <Modal
        title="Student Notes"
        visible={notesVisible}
        onCancel={handleNotesClose}
        footer={[
          <Button key="close" onClick={handleNotesClose}>
            Close
          </Button>,
        ]}
      >
        <Table
          dataSource={notesData}
          columns={[
            {
              title: 'Note ID',
              dataIndex: 'noteId',
              key: 'noteId',
            },
            {
              title: 'Author',
              dataIndex: 'autherPerson',
              key: 'autherPerson',
            },
            {
              title: 'Date & Time',
              dataIndex: 'dateTime',
              key: 'dateTime',
            },
            {
              title: 'Note',
              dataIndex: 'note',
              key: 'note',
            },
          ]}
        />
      </Modal>
    </>
  )
}

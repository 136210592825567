import React, { useState, useEffect } from 'react'
import { Table, message } from 'antd'
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import axios from 'axios'

export default function StudentSubjects() {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const headers = {}
  const [cardNumber, setCardNumber] = useState(1)
  const [error, setError] = useState(null)
  const [englishCoreCourses, setEnglishCoreCourses] = useState([])
  const [mathCoreCourses, setMathCoreCourses] = useState([])
  const [historyCoreCourses, setHistoryCoreCourses] = useState([])
  const [foreignLanguageCoreCourses, setForeignLanguageCoreCourses] = useState([])
  const [scienceCoreCourses, setScienceCoreCourses] = useState([])
  const [electiveCourses, setElectiveCourses] = useState([])

  const [formData, setFormData] = useState({
    studentId: '',
    counselorId: userId,
    recommendCourses: [],
  })

  useEffect(() => {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    fetchCourses('EnglishCore', setEnglishCoreCourses)
    fetchCourses('MathCore', setMathCoreCourses)
    fetchCourses('HistoryCore', setHistoryCoreCourses)
    fetchCourses('ForeignLanguageCore', setForeignLanguageCoreCourses)
    fetchCourses('ScienceCore', setScienceCoreCourses)
    fetchCourses('ElectiveSubjects', setElectiveCourses)
  }, [])

  const fetchCourses = async (courseType, setCourseState) => {
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/Course/CoreAndElective?name=${courseType}`,
        {
          headers: headers,
        },
      )
      setCourseState(response.data)
    } catch (error) {
      setError('Error fetching courses')
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleCourseChange = (e, year) => {
    const { value } = e.target
    console.log('Selected course name:', value)

    // Find the course in the appropriate course list
    const course = [
      ...englishCoreCourses,
      ...mathCoreCourses,
      ...historyCoreCourses,
      ...foreignLanguageCoreCourses,
      ...scienceCoreCourses,
      ...electiveCourses,
    ].find((course) => course.courseName === value)

    console.log('Matched course:', course)

    if (course) {
      const updatedCourses = [...formData.recommendCourses, { courseId: course.courseId, year }]
      setFormData({ ...formData, recommendCourses: updatedCourses })
      console.log('Updated recommendCourses:', updatedCourses)
    }
  }

  const handleSubmitClick = async () => {
    // Ensure studentId and counselorId are numbers
    const payload = {
      ...formData,
      studentId: Number(formData.studentId),
      counselorId: Number(userId),
    }

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/Course/Add-Recommend-Courses',
        payload,
        {
          headers: headers,
        },
      )
      // Handle response
      console.log(response.data)
      message.success(response.data.message)
    } catch (error) {
      console.log(error)
      message.error('Error in submitting')
    }
  }

  const handleNextButtonClick = () => {
    // Increment card number
    setCardNumber((prevCardNumber) => prevCardNumber + 1)
  }

  const handlePreviousButtonClick = () => {
    setCardNumber((prevCardNumber) => Math.max(prevCardNumber - 1, 1))
  }
  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            {cardNumber === 1 && (
              <div className="card">
                <div className="card-header" style={{ position: 'relative' }}>
                  <h5>Freshman Subjects</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                          style={{ fontSize: '15px' }}
                        >
                          Student Id
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Student Id here"
                          name="studentId"
                          value={formData.studentId}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        English Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'freshman')}
                      >
                        <option value="Please Select">Please Select</option>
                        {englishCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Math Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'freshman')}
                      >
                        <option value="Please Select">Please Select</option>
                        {mathCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        History Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'freshman')}
                      >
                        <option value="Please Select">Please Select</option>
                        {historyCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Foreign Language Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'freshman')}
                      >
                        <option value="Please Select">Please Select</option>
                        {foreignLanguageCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Science Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'freshman')}
                      >
                        <option value="Please Select">Please Select</option>
                        {scienceCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Elective Subjects
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'freshman')}
                      >
                        <option value="Please Select">Please Select</option>
                        {electiveCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-12">
                      <button
                        style={{ float: 'inline-end' }}
                        className="btn btn-primary"
                        onClick={handleNextButtonClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {cardNumber === 2 && (
              <div className="card">
                <div className="card-header" style={{ position: 'relative' }}>
                  <h5>Sophomore Subjects</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        English Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'sophomore')}
                      >
                        <option value="Please Select">Please Select</option>
                        {englishCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Math Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'sophomore')}
                      >
                        <option value="Please Select">Please Select</option>
                        {mathCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        History Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'sophomore')}
                      >
                        <option value="Please Select">Please Select</option>
                        {historyCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Foreign Language Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'sophomore')}
                      >
                        <option value="Please Select">Please Select</option>
                        {foreignLanguageCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Science Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'sophomore')}
                      >
                        <option value="Please Select">Please Select</option>
                        {scienceCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Elective Subjects
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'sophomore')}
                      >
                        <option value="Please Select">Please Select</option>
                        {electiveCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-12">
                      <button
                        style={{ float: 'inline-end' }}
                        className="btn btn-primary"
                        onClick={handleNextButtonClick}
                      >
                        Next
                      </button>
                    </div>
                    <div className="col-md-12">
                      <button
                        style={{ marginTop: '-75px' }}
                        className="btn btn-outline-dark"
                        onClick={handlePreviousButtonClick}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {cardNumber === 3 && (
              <div className="card">
                <div className="card-header" style={{ position: 'relative' }}>
                  <h5>Junior Grade Subjects</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        English Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'junior')}
                      >
                        <option value="Please Select">Please Select</option>
                        {englishCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Math Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'junior')}
                      >
                        <option value="Please Select">Please Select</option>
                        {mathCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        History Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'junior')}
                      >
                        <option value="Please Select">Please Select</option>
                        {historyCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Foreign Language Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'junior')}
                      >
                        <option value="Please Select">Please Select</option>
                        {foreignLanguageCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Science Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'junior')}
                      >
                        <option value="Please Select">Please Select</option>
                        {scienceCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Elective Subjects
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'junior')}
                      >
                        <option value="Please Select">Please Select</option>
                        {electiveCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-12">
                      <button
                        style={{ float: 'inline-end' }}
                        className="btn btn-primary"
                        onClick={handleNextButtonClick}
                      >
                        Next
                      </button>
                    </div>
                    <div className="col-md-12">
                      <button
                        style={{ marginTop: '-75px' }}
                        className="btn btn-outline-dark"
                        onClick={handlePreviousButtonClick}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {cardNumber === 4 && (
              <div className="card">
                <div className="card-header" style={{ position: 'relative' }}>
                  <h5>Senior Grade Subjects</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        English Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'senior')}
                      >
                        <option value="Please Select">Please Select</option>
                        {englishCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Math Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'senior')}
                      >
                        <option value="Please Select">Please Select</option>
                        {mathCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        History Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'senior')}
                      >
                        <option value="Please Select">Please Select</option>
                        {historyCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Foreign Language Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'senior')}
                      >
                        <option value="Please Select">Please Select</option>
                        {foreignLanguageCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Science Core
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'senior')}
                      >
                        <option value="Please Select">Please Select</option>
                        {scienceCoreCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label" style={{ fontSize: '15px' }}>
                        Elective Subjects
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleCourseChange(e, 'senior')}
                      >
                        <option value="Please Select">Please Select</option>
                        {electiveCourses.map((course) => (
                          <option key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-12">
                      <button
                        style={{ float: 'inline-end' }}
                        className="btn btn-primary"
                        onClick={handleSubmitClick}
                      >
                        Submit
                      </button>
                    </div>
                    <div className="col-md-12">
                      <button
                        style={{ marginTop: '-75px' }}
                        className="btn btn-outline-dark"
                        onClick={handlePreviousButtonClick}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Form, Input, Button, message, Select } from 'antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import axios from 'axios'

const { Option } = Select

export default function Notes() {
  const [isLoading, setIsLoading] = useState(false)
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const [students, setStudents] = useState([])
  const headers = {}

  const [form] = Form.useForm()

  const handleSubmit = async (values) => {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    try {
      setIsLoading(true)
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/Note',
        {
          studentId: values.studentId,
          autherId: userId,
          dateTime: values.dateTime,
          content: values.content,
        },
        {
          headers: headers,
        },
      )
      if (response.status === 200) {
        message.success('Note added successfully')
        form.resetFields()
      } else {
        message.error('Failed to add note')
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          message.error(`Failed: ${error.response.data}`)
        } else {
          message.error(`Submission failed: ${error.response.data}`)
        }
      } else {
        console.error('Error adding note:', error)
        message.error('An error occurred while adding the note')
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchStudents()
  }, [])

  const fetchStudents = () => {
    fetch(
      `https://soft.recipe4foodies.com/api/Session/Get-Counselor-Students?counselorId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        setStudents(data)
      })
      .catch((error) => console.error('Error fetching students:', error))
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: 'relative' }}>
              <h5>Session Notes</h5>
            </div>
            <div className="card-body">
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                  studentId: '',
                  dateTime: null,
                  content: '',
                }}
              >
                <Form.Item
                  label="Student Id"
                  name="studentId"
                  rules={[{ required: true, message: 'Please select a student ID' }]}
                  placeholder="Select a Student Id"
                >
                  <Select id="studentIds" style={{ width: '100%' }}>
                    {students.map((student) => {
                      return (
                        <Option key={student.studentId} value={student.studentId}>
                          {`${student.studentId}, ${student.name}`}{' '}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Set Date Time"
                  name="dateTime"
                  rules={[{ required: true, message: 'Please select a date and time' }]}
                >
                  <Input
                    type="datetime-local"
                    className="form-control"
                    placeholder="Enter Date Time here"
                  />
                </Form.Item>

                <Form.Item
                  label="Notes"
                  name="content"
                  rules={[{ required: true, message: 'Please add your notes' }]}
                >
                  <Input.TextArea rows={3} placeholder="Add Notes" />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: '15%',
                      maxWidth: '200px',
                      minWidth: '150px',
                      padding: '10px 20px',
                      boxSizing: 'border-box',
                    }}
                  >
                    {isLoading ? 'Please Wait...' : 'Submit'}
                    {isLoading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

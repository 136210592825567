import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Table, Button, Modal, message, Tooltip } from 'antd'
import 'antd/dist/antd'
import SoftBox from 'src/components/SoftBox'
import SoftTypography from 'src/components/SoftTypography'
import PropTypes from 'prop-types'
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import zoom from 'src/assets/images/Zoom-icon.png'
import { useLocation } from 'react-router-dom'

function Author({ name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  )
}

Author.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
}

const CounselorSession = () => {
  const [showDetails, setShowDetails] = useState(true)
  const [showRequestedSessions, setShowRequestedSessions] = useState(false)
  const [meetingData, setMeetingData] = useState([])
  const [selectedMeeting, setSelectedMeeting] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setIsloading] = useState(false)
  const location = useLocation()
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')

  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        setIsloading(true)
        const response = await axios.get(
          `https://soft.recipe4foodies.com/api/Session/Get-Counselor-Sessions?counselorId=${userId}&upcomingOnly=true`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        setMeetingData(response.data)
      } catch (error) {
        console.error('Error fetching meetings:', error)
        message.error('Error fetching meetings')
      } finally {
        setIsloading(false)
      }
    }

    if (token) {
      fetchMeetings()
    }
  }, [token, userId])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const meetingId = queryParams.get('id')

    if (meetingId && meetingData.length > 0) {
      const meeting = meetingData.find((meeting) => meeting.sessionId === Number(meetingId))
      if (meeting) {
        setSelectedMeeting(meeting)
        setIsModalVisible(true)
      }
    }
  }, [location.search, meetingData])

  const handleZoomIconClick = (record) => {
    setSelectedMeeting(record)
    setIsModalVisible(true)
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
    setSelectedMeeting(null)
  }

  const handleBackButtonClick = () => {
    setShowDetails(true)
    setShowRequestedSessions(false)
  }

  const meetingColumns = [
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
    },
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      key: 'studentName',
    },
    {
      title: 'Student Email',
      dataIndex: 'studentEmail',
      key: 'studentEmail',
      render: (text) => {
        const maxLength = 8
        const truncatedText = text.length > maxLength ? `${text.slice(0, maxLength)}...` : text

        return (
          <Tooltip title={text}>
            <span>{truncatedText}</span>
          </Tooltip>
        )
      },
    },
    {
      title: 'Local Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'US TimeZone',
      dataIndex: 'startTimeUSTimeZone',
      key: 'startTimeUSTimeZone',
      render: (text, record) => {
        const date = new Date(text)
        const formattedDate = date.toLocaleDateString('en-US', {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        })

        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })

        const fullTimezone = record.timezone || '' // Ensure it's defined
        let timezoneAbbreviation = ''

        // Match timezone abbreviation only if it exists
        if (fullTimezone) {
          const timezoneMatch = fullTimezone.match(/\(([^)]+)\)/)
          timezoneAbbreviation = timezoneMatch ? timezoneMatch[1] : '' // Abbreviation or empty
        }

        // Ensure tooltip displays full timezone and abbreviation is formatted correctly
        return (
          <Tooltip title={fullTimezone || 'Timezone not available'}>
            {`${formattedDate}, ${formattedTime}${
              timezoneAbbreviation ? ` (${timezoneAbbreviation})` : ''
            }`}
          </Tooltip>
        )
      },
    },
    {
      title: 'Session Type',
      dataIndex: 'sessionType',
      key: 'sessionType',
    },
    {
      title: 'Session Mode',
      dataIndex: 'sessionMode',
      key: 'sessionMode',
    },
    {
      title: 'Subject',
      dataIndex: 'topic',
      key: 'topic',
    },
    {
      title: 'Link',
      dataIndex: 'startUrl',
      key: 'startUrl',
      render: (text, record) => (
        <a onClick={() => handleZoomIconClick(record)}>
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            <img src={zoom} alt="Start Link" style={{ width: '40px', height: '40px' }} />
          </SoftTypography>
        </a>
      ),
    },
  ]

  return (
    <>
      <CCardBody>
        {showDetails ? (
          <CCard>
            <CCardHeader
              style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}
            >
              <b>Counselor Session</b>
            </CCardHeader>
            <CCardBody>
              <Table
                className="table-responsive"
                dataSource={meetingData}
                columns={meetingColumns}
                bordered
                loading={loading}
                pagination={true}
              />
            </CCardBody>
          </CCard>
        ) : showRequestedSessions ? (
          <div>
            <h6>Requested Sessions Content</h6>
            <Button onClick={handleBackButtonClick}>Back</Button>
          </div>
        ) : (
          <div>
            <h6>New Screen Content</h6>
            <Button onClick={handleBackButtonClick}>Back</Button>
          </div>
        )}
      </CCardBody>
      {selectedMeeting && (
        <Modal
          title="Meeting Details"
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={[
            <Button key="close" onClick={handleModalClose}>
              Close
            </Button>,
            <Button key="join" type="primary" href={selectedMeeting.startUrl} target="_blank">
              Start Meeting
            </Button>,
          ]}
        >
          <p>
            <b>Session ID:</b> {selectedMeeting.sessionId}
          </p>
          <p>
            <b>Time:</b> {new Date(selectedMeeting.startTime).toLocaleString()}
          </p>
          <p>
            <b>Duration:</b> {selectedMeeting.duration} minutes
          </p>
          <p>
            <b>Meeting ID:</b> {selectedMeeting.meetingId}
          </p>
          <p>
            <b>Passcode:</b> {selectedMeeting.password}
          </p>
        </Modal>
      )}
    </>
  )
}

export default CounselorSession
